import { useContext }                       from "react";
import { useNavigate }                      from "react-router-dom";

import Menu                                 from "@mui/material/Menu";
import MenuItem                             from '@mui/material/MenuItem';
import Typography                           from '@mui/material/Typography';

import CreateSvg                            from "../Common/CreateSvg";
import { gen_coin_type, total_values }      from "../../Utils/StoreFunction";
import { EXIT_ACCOUNT }                     from "../../Utils/UserFunction";

import Context                              from "../../Provider/Context";

import pt_br_icon                           from "../../assets/img/languages/pt_br_icon.png";
import eng_icon                             from "../../assets/img/languages/eng_icon.png";
import { _save_storage, storage_vars, 
  _remove_storage }                         from "../../Storage/Storage";

/* IMPORT LANGUAGE */
import { _lang_menus, _lang_base }          from "../../Languages/languages";

const lang_options = [
  {
    code: "pt-BR",
    name: "Português",
    icon: pt_br_icon
  },
  {
    code: "en",
    name: "English",
    icon: eng_icon
  }
]

function Menus(props) {
  const history                               = useNavigate();
  const { ProductsCart, UserNotifications, 
    Coupons }                                 = useContext(Context);

  const products_cart                         = ProductsCart.Value;
  const user_notifications                    = UserNotifications.Value.filter((item) => (!item.notification_visible));
  const CouponsInfo                           = Coupons.Value;

  const value_total                           = total_values(products_cart, CouponsInfo);
  const remove_item                           = (item_current) => ProductsCart.Update(ProductsCart.Value.filter((item) => item.product_id !== item_current)); 

  const mark_all_notifications                = () => 
    UserNotifications.Update(UserNotifications.Value.map((item) => ({...item, notification_visible: true})));

  const mark_notifications                    = (notification) => 
    UserNotifications.Update(UserNotifications.Value.map((item) => 
      (item.notification_id === notification.notification_id) ? {...item, notification_visible: true} : item));

  const component_products_cart               = products_cart.map((item, index) => 
    <div key={index} className="dropdown-box-list-item menu_cart">
      <div className="cart-item-preview">
        <div className="cart-item-preview-image">
          <figure className="picture medium round liquid" style={{
            background: `url('https://projectdow.com/data${item.product_base_store_img}') center center / cover no-repeat`}} />
        </div>
        <p className="cart-item-preview-title">{item.product_information.product_title}</p>
        <p className="cart-item-preview-text">{item.product_information.product_category}</p>
        <p className="cart-item-preview-price">{gen_coin_type(item.product_information.product_discont, _lang_base.currency)} x {item.product_amount}</p>
        <div className="cart-item-preview-action" onClick={() => remove_item(item.product_id)}><CreateSvg svgname="svg_delete" svgclassname="icon-delete" /></div>
      </div>
    </div>
  )

  const user_nav_settings = [
    {
      page_link: "/profile",
      page_name: _lang_menus.user_header_btn.profile
    },
    {
      page_link: "/hub/myorders",
      page_name: _lang_menus.user_header_btn.orders
    },
    // {
    //   page_link: "/settings",
    //   page_name: _lang_menus.user_header_btn.settings
    // },
  ];

  const component_user_notifications          = user_notifications.map((item, index) => 
    <div onClick={() => mark_notifications(item)} key={index} className="dropdown-box-list-item dropdown-box-list-item-notification">
      <div className="user-status notification">
        <div className="user-status-avatar">
          <div className="user-avatar small no-outline">
            <div className="user-avatar-content">
              <div className="hexagon-image-30-32">
                <img className="reaction" src={item.notification_user_avatar} alt={_lang_base.web_site_name} />
              </div>
            </div>                      
          </div>
        </div>
        <p className="user-status-title">
          <span className="bold">{item.notification_user_name}</span> {item.notification_description}
        </p>
        <p className="user-status-timestamp">{item.notification_date}</p>
        <div className="user-status-icon">
          <CreateSvg svgname="svg_comment" svgclassname="icon-thumbs-up" />
        </div>
      </div>
    </div>
  )

  const component_not_have_notifications = (user_notifications.length <= 0) && 
    <div className="not_have_notifications">
      <p>{_lang_menus.not_have_notifications}</p>
    </div>

  return(
    <>
      <div className="menu_cart_container">
        <Menu
          anchorEl={props.carrinho.carrinho_open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          id={"menu_carrinho"}
          // keepMounted
          disableScrollLock={true}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={(props.carrinho.carrinho_open !== false) ? true : false}
          onClose={(event) => props.menu_handle(event, props.carrinho.set_carrinho_open, false)}
          sx={{ backgroundColor: "transparent", boxShadow: "none"}}
        >
          <div className="dropdown-box no-padding-bottom header-dropdown profile">
            <div className="dropdown-box-header">
              <p className="dropdown-box-header-title">{_lang_menus.cart_title} <span className="highlighted">{products_cart.length}</span></p>
            </div>
            <div className="dropdown-box-list scroll-small no-hover">{component_products_cart}</div>
            <div className="cart-preview-total">
              <p className="cart-preview-total-title">{_lang_menus.cart_total_title}</p>
              <p className="cart-preview-total-text">{gen_coin_type(value_total, _lang_base.currency)}</p>
            </div>
            <div className="dropdown-box-actions">
              <div className="dropdown-box-action">
                <div className="button secondary" onClick={() => { history('/cart'); props.carrinho.set_carrinho_open(false); }}>{_lang_menus.btn_cart}</div>
              </div>
              <div className="dropdown-box-action">
                <div className="button primary"  onClick={() => { history('/checkout'); props.carrinho.set_carrinho_open(false); }}>{_lang_menus.btn_finalize_purchase}</div>
              </div>
            </div>
          </div>
        </Menu>
      </div>

      <div className="menu_lang_container">
        <Menu
          anchorEl={props.language.language_open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          id={"menu_language"}
          keepMounted
          disableScrollLock={true}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={(props.language.language_open !== false) ? true : false }
          onClose={(event) => props.menu_handle(event, props.language.set_language_open, false)}
          sx={{ backgroundColor: "transparent", boxShadow: "none", background: "none" }}
        >
          <div className="dropdown_language">
            <ul className="dropdown-menu show" aria-labelledby="Dropdown">
              {lang_options.map((linguagem, index) => 
                <li onClick={() => {
                  _save_storage(storage_vars.language, linguagem.code);
                   window.location.reload(); 
                  _remove_storage(storage_vars.cart);
                  _remove_storage(storage_vars.coupons);
                  }} key={`lang-${index}`} className="dropdown-item drop_lang">
                  <img src={linguagem.icon} alt={_lang_base.web_site_name} /> {linguagem.name}
                </li>
              )}
            </ul>
          </div>
        </Menu>
      </div>

      <div className="menu_notificacoes_container">
        <Menu
          anchorEl={props.notifica.notifica_open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          id={"menu_notifica"}
          keepMounted
          disableScrollLock={true}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={(props.notifica.notifica_open !== false) ? true : false}
          onClose={(event) => props.menu_handle(event, props.notifica.set_notifica_open, false)}
          sx={{ backgroundColor: "transparent", boxShadow: "none" }}
        >
          <div className="dropdown-box header-dropdown profile">
            <div className="dropdown-box-header">
              <p className="dropdown-box-header-title">{_lang_menus.notication_title}</p>
              <div className="dropdown-box-header-actions">
                <p onClick={mark_all_notifications} className="dropdown-box-header-action">{_lang_menus.btn_brand_everything_as_read}</p>
                <p className="dropdown-box-header-action">{_lang_menus.btn_configuration}</p>
              </div>
            </div>
            <div className="dropdown-box-list">
              {component_user_notifications}
              {component_not_have_notifications}
            </div>
            <p className="dropdown-box-button secondary">{_lang_menus.btn_see_all_notifications}</p>
          </div>
        </Menu>
      </div>

      <div className="menu_user_container">
        <Menu
          anchorEl={props.user_menu.user_menu_open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          id={"menu_notifica"}
          keepMounted
          disableScrollLock={true}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={(props.user_menu.user_menu_open !== false) ? true : false}
          onClose={(event) => props.menu_handle(event, props.user_menu.set_user_menu_open, false)}
          sx={{ backgroundColor: "transparent", boxShadow: "none" }}
        >
          <div className="dropdown_user_nav_bar">
            <ul className="dropdown-menu show" aria-labelledby="Dropdown">
              {user_nav_settings.map((setting, index) => 
                <MenuItem key={`user_li_${index}`} onClick={() => {props.user_menu.set_user_menu_open(false); history(setting.page_link); }}>
                  <Typography textAlign="center">{setting.page_name}</Typography>
                </MenuItem>
              )}
                <MenuItem onClick={EXIT_ACCOUNT}>
                  <Typography textAlign="center">{_lang_menus.user_header_btn.logout}</Typography>
                </MenuItem>
            </ul>
          </div>
        </Menu>
      </div>
    </>
  )
}

export default Menus;
