import { useContext, useEffect }                     from "react";
import { useParams, useNavigate }                    from "react-router-dom";
import Zoom                                          from "react-medium-image-zoom";

import LocalShippingIcon                             from "@mui/icons-material/LocalShipping";
import SupportAgentIcon                              from '@mui/icons-material/SupportAgent';

import Context                                       from "../../Provider/Context";

import { SOCKET_ANALYTICS }                          from "../../Socket/Conexao";
import Footer                                        from "../../Components/Footer/Footer";
import ComponentNotFound                             from "../../Components/404/404";

import { gen_coin_type }                             from "../../Utils/StoreFunction";

/* Import Images */
import shop_shape                                    from "../../assets/img/store/shop_shape.svg";

/* IMPORT LANGUAGE */
import { _lang_product, _lang_base }                 from "../../Languages/languages";

const PageProduct = () => {  
  const history                              = useNavigate();
  const { Products, ProductsCart, Chat,
    UserInfoBase}                            = useContext(Context);
  const { product }                          = useParams();

  const UserInfo                             = UserInfoBase.Value;
  const product_info                         = [...Products.free, ...Products.vip].find(item => item.product_slug === product);

  useEffect(() => {
    if (Products.vip !== undefined                     && 
        Products.free !== undefined                    && 
        product_info !== undefined                     && 
        product_info.product_information !== undefined)
        {
          SOCKET_ANALYTICS.emit('product_access', {user: ((UserInfo && UserInfo.user !== undefined)) ? UserInfo.user : undefined, product: {
            product_title: product_info.product_information.product_title,
            product_id: product_info.product_id,
            product_value: product_info.product_information.product_discont,
          }});
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_info]);

  useEffect(() => {window.scrollTo({top: 0,behavior: 'smooth'});}, []);
  if(Products.vip === undefined || Products.free === undefined) return (<ComponentNotFound />);
  if (!product_info                            || 
      !product_info.product_information        || 
      product_info.product_category_id !== 1   || 
      product_info.product_enable_store !== 1  || 
      product_info.product_type_id === 1) return <ComponentNotFound />;

  const product_properties                = [
    {
      title: _lang_product.product_properties.product_title,
      value: product_info.product_information.product_title,
    },
    {
      title: _lang_product.product_properties.product_status,
      value: product_info.product_information.product_status,
    },
    {
      title: _lang_product.product_properties.product_platform,
      value: product_info.product_platform,
    },
    {
      title: _lang_product.product_properties.product_compatibility,
      value: product_info.product_compatible,
    },
    {
      title: _lang_product.product_properties.product_duration,
      value: product_info.product_information.product_duration,
    },
  ];

  const buy_with_support                  = () => {
    Chat.Update(true);
  }

  const add_item_cart                     = () => {
    const scan_product = ProductsCart.Value.find((item) => item.product_id === product_info.product_id);
    if(scan_product) ProductsCart.Update(ProductsCart.Value.map((item) => (item.product_id === product_info.product_id) ? {...item, product_amount: item.product_amount + 1 } : item))
    else ProductsCart.Update([...ProductsCart.Value, {...product_info, product_amount: 1}]);
    SOCKET_ANALYTICS.emit('add_cart', {user: ((UserInfo && UserInfo.user !== undefined)) ? UserInfo.user : undefined, product: {
      product_title: product_info.product_information.product_title,
      product_id: product_info.product_id,
      product_value: product_info.product_information.product_discont,
    }});
  }

  const components_gerate_pre_imgs = 
    <div className={`tab-pane show active`}>
      <figure className="img_pre_product" style={{
        background: `url('https://projectdow.com/data${product_info.product_base_store_img}') center center / cover no-repeat`}}>  
      </figure>
    </div> 

  const components_gerate_pre_imgs_icons = product_info.product_imgs.map((item, index) => 
    <li key={`pre_img_icons_${index}`} className="nav-item">
      <div className="nav-link">
        <Zoom>
          <img className="img_product_zoom" src={`https://projectdow.com/data${item}`} alt={_lang_base.web_site_name} />
        </Zoom>
      </div>
    </li>
  )

  const components_product_properties = product_properties.map((item, index) => 
    <tr className="informations_store" key={`item_compatibilidade_${index}`}>
      <th scope="row">{item.title}</th>
      <td>{item.value}</td>
    </tr>
  )

  const components_description_product = (product_info.product_category_id === 1) ?
    <iframe width="100%" height="100%" 
      src={product_info.product_video} 
      title="YouTube video player" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      allowFullScreen
    >
    </iframe>
    :
    <img width="100%" height="100%" className="img_product_zoom" src={`https://projectdow.com/data${product_info.product_information.product_description_img}`} alt={_lang_base.web_site_name} />

  const components_immediate_delivery = (product_info.product_immediate_delivery) ?
    <div className="shop__details-short-description">
      <span className="informacoes"><LocalShippingIcon />  {_lang_product.descript_delivery}</span>
    </div> :
    <div className="shop__details-short-description">
      <span className="informacoes"><SupportAgentIcon />  {_lang_product.descript_delivery_support}</span>
    </div>

  const components_gerate_products_related = Products.vip.sort(() => 0.5 - Math.random()).slice(0, 4).map((item, index) => (item.product_slug !== product) &&
    <div className="col" key={index} onClick={()=> {history(`/store/${item.product_slug}`); window.scrollTo({top: 0,behavior: 'smooth'});}}> 
      <div className="shop__item" style={ { "backgroundImage" : `url('${shop_shape}')`, "--delay:" : ".4s;" } }>
        <div className="shop__item-thumb"><img src={`https://projectdow.com/data${item.product_base_store_img}`} alt={_lang_base.web_site_name} /></div>
        <div className="shop__item-line"></div>
        <div className="shop__item-content">
          <div className="shop__item-content-top">
            <h4 className="title">{item.product_information.product_title}</h4>
            <div className="shop__item-price">{gen_coin_type(item.product_information.product_value, _lang_base.currency)}</div>
          </div>
          <div className="shop__item-cat">{item.product_information.product_title}</div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <main className="main--area">
        <section className="shop-area shop-details-area">
          <div className="container">
            <div className="row">
              <div className="shop__details-images-wrap">
                <ul className="nav nav-tabs" id="imageTab" role="tablist">
                  <li className="nav-item">
                    <div className="nav-link">
                      <Zoom>
                        <img className="img_product_zoom" src={`https://projectdow.com/data${product_info.product_base_store_img}`} alt={_lang_base.web_site_name} />
                      </Zoom>
                    </div>
                  </li>
                  {components_gerate_pre_imgs_icons}
                </ul>
                <div className="tab-content" id="imageTabContent">{components_gerate_pre_imgs}</div>
              </div>
              <div className="shop__details-content">
                <h2 className="title">{product_info.product_title}</h2>
                <div className="shop__details-price">
                  <span className="product_value">{gen_coin_type(product_info.product_information.product_value, _lang_base.currency)}</span>
                  <span className="product_discont">{gen_coin_type(product_info.product_information.product_discont, _lang_base.currency)}</span>
                  {(product_info.product_information.product_manage_stock === "yes") && (
                    <span className={`slots-page-product ${product_info.product_information.product_stock_status}`}>
                      {product_info.product_information.product_stock_status === "outofstock"
                        ? "0 SLOTS"
                        : `${product_info.product_information.product_stock} ${product_info.product_information.product_stock > 1 ? "SLOTS" : "SLOT"}`}
                    </span>
                  )}
                </div>
                <div className="shop__details-qty">
                  <div className="cart-plus-minus d-flex flex-wrap align-items-center">
                    <button 
                      onClick={() => (product_info.product_immediate_delivery) ? add_item_cart() : buy_with_support()} 
                      className="shop__details-cart-btn"
                    > 
                      {(product_info.product_immediate_delivery) ? _lang_product.btn_add_cart : _lang_product.btn_buy_with_support}
                    </button>
                  </div>
                </div>
                {components_immediate_delivery}
                <div className="shop__details-bottom">
                  <div className="tab-pane animation-none fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                    <table className="table table-sm">
                      <tbody>{components_product_properties}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="product__desc-wrap">
                  <div className="tab-content" id="descriptionTabContent">
                    <div className="tab-pane animation-none show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                      {components_description_product}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related__product-area">
              <div className="related__product-wrapper">
                <h2 className="related-title">{_lang_product.related_title}</h2>
                <div className="row justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1"> 
                  {components_gerate_products_related}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default PageProduct;
