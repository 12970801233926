import { useRef, useContext }                           from "react";
import { Swiper, SwiperSlide }                          from "swiper/react";
import { Autoplay, Pagination, Navigation }             from 'swiper/modules';
import { useNavigate }                                  from "react-router-dom";
import Context                                          from "../../Provider/Context";
import { _lang_base }                                   from "../../Languages/languages";

function SlideNewProducts() {
  const { Products }                                  = useContext(Context);
  const history                                       = useNavigate();
  const progressCircle                                = useRef(null);
  const progressContent                               = useRef(null);
  const onAutoplayTimeLeft                            = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  if(Products.vip === undefined || Products.free === undefined || Products.slide_count === undefined) return;

  const slide_cont_formated = Array.from({ length: Products.slide_count }, (_, index) => {
    return (_lang_base.lang_base === "pt_BR") ? `/assets/img/store/slide/pt_BR/${Products.slide_count - index}.jpg` 
                                              : `/assets/img/store/slide/en/${Products.slide_count - index}.jpg` ;
  });

  return(
    <Swiper 
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{delay: 7000, disableOnInteraction: false}}
      pagination={{clickable: true}}
      navigation={true}
      rewind={true}
      modules={[Autoplay, Pagination, Navigation]}
      onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
    >
      {slide_cont_formated.map((item, index) =>
      <SwiperSlide key={index} onClick={() => history(`/store`)}>
        <img src={`https://projectdow.com/data${item}`} alt={_lang_base.web_site_name} />
      </SwiperSlide>
      )}
      <div className="autoplay-progress" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span ref={progressContent}></span>
      </div>
    </Swiper>
  )
}

export default SlideNewProducts;
