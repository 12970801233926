import { useState, useContext, useEffect }            from "react";
import { useNavigate, useLocation   }                 from "react-router-dom";
import Hexagon                                        from "react-hexagon"

import { useSnackbar }                                from 'notistack';
import Tooltip                                        from "@mui/material/Tooltip";

import { EXIT_ACCOUNT }                               from "../../Utils/UserFunction";
import { product_list_function }                      from "../../Utils/DesignerFunction";
import CreateSvg                                      from "../Common/CreateSvg";
import Context                                        from "../../Provider/Context";

/* IMPORT IMAGENS */
import base_icon                                      from "../../assets/img/badge/base_icon.png";
import base_backgoround                               from "../../assets/img/badge/base.png";

/* IMPORT LANGUAGE */
import { _lang_header_vertical_layout, _lang_base }   from "../../Languages/languages";

function HeaderVerticalLayout() {
  const history                                       = useNavigate();
  const location                                      = useLocation();

  const { UserInfoBase, SocialLinks, UserConfigBase, 
    DialogBase }                                      = useContext(Context);
  const UserInfo                                      = UserInfoBase.Value;
  const UserConfig                                    = UserConfigBase.Value;

  const [menu_colapse, set_menu_colapse]              = useState(UserConfig.header_vertical_status);
  const [menu_alt, set_menu_alt]                      = useState(menu_colapse);

  const [menu_mobile, set_menu_mobile]                = useState(false);
  const [menu_alt_mob, set_menu_alt_mob]              = useState(menu_mobile);

  const { enqueueSnackbar }                           = useSnackbar();
  const current_time                                  = Math.floor(Date.now() / 1000);

  const pages_links                                     = [
    {
      page_link: "/ui",
      page_name: _lang_header_vertical_layout.pages_links.page_mods,
      menu_svg_icon: <CreateSvg svgname="svg_newsfeed" svgclassname="menu-item-link-icon icons_menu" />
    },
    {
      page_link: "/acess",
      page_name: _lang_header_vertical_layout.pages_links.page_my_acess,
      menu_svg_icon: <CreateSvg svgname="svg_marketplace" svgclassname="menu-item-link-icon icons_menu" />
    },
    {
      page_link: "/recharge",
      page_name: _lang_header_vertical_layout.pages_links.page_recharge,
      menu_svg_icon: <CreateSvg svgname="svg_return" svgclassname="menu-item-link-icon icons_menu" />
    },
    {
      page_link: "/activekey",
      page_name: _lang_header_vertical_layout.pages_links.page_active_key,
      menu_svg_icon: <CreateSvg svgname="svg_status" svgclassname="menu-item-link-icon icons_menu" />
    },
    {
      page_link: "/requestmod",
      page_name: _lang_header_vertical_layout.pages_links.page_request_mod,
      menu_svg_icon: <CreateSvg svgname="svg_forums" svgclassname="menu-item-link-icon icons_menu" />
    },
    {
      page_link: "/download",
      page_name: _lang_header_vertical_layout.pages_links.page_download_loader,
      menu_svg_icon: <CreateSvg svgname="svg_public" svgclassname="menu-item-link-icon icons_menu" />
    },
  ];

  const colapse_menu                     = ()         => {
    set_menu_colapse(!menu_colapse);
    UserConfigBase.Update({header_vertical_status: !menu_colapse});
  };
  
  const colapse_menu_mobile              = ()         => set_menu_mobile(!menu_mobile);
  const handleResize                     = ()         => {
    if(window.innerWidth < 575) {
      set_menu_colapse(false);
      set_menu_mobile(true);
    }
  }

  useEffect(()                                        => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(()                                        => {setTimeout(() => set_menu_alt(menu_colapse), 300);}, [menu_colapse]);
  useEffect(()                                        => {setTimeout(() => set_menu_alt_mob(menu_mobile), 300);}, [menu_mobile]);

  if(!UserInfo || UserInfo.user === undefined) return (<></>) ;

  const user_infos                                    = (UserInfo.user_info) ? {
    user_login:       UserInfo.user.user_login.substring(0, 18),
    user_phrase:      UserInfo.user_info.user_phrase.substring(0, 25),
    user_level:       UserInfo.user_info.user_level,
    user_avatar:      UserInfo.user_info.user_avatar,
    user_background:  UserInfo.user_info.user_background,
    user_posts:       UserInfo.user_info.user_posts,
    user_folowers:    UserInfo.user_info.user_folowers,
    user_visits:      UserInfo.user_info.user_visits,
    user_badges:      [],
  } : {
    user_login:       UserInfo.user.user_login.substring(0, 18),
    user_phrase:      "",
    user_level:       "0",
    user_avatar:      "https://projectdow.com/data/assets/img/avatar/perfil.jpg",
    user_background:  "https://projectdow.com/data/assets/img/avatar/background.jpg",
    user_posts:       "0",
    user_folowers:    "0",
    user_visits:      "0",
    user_badges:      [],
  };

  const component_menu_li       = (pages_links.map((item, index) => 
      <li key={index} 
        className={`menu-item ${(location.pathname === item.page_link) ? 'active' : ''}`} 
        onClick={() => {
          switch(item.page_link) {
            case "/acess":
              product_list_function(true);
              break;
            case "/recharge":
              if(UserInfo.product_free.length > 0 && UserInfo.product_free[0].access_recharge > current_time) {
                enqueueSnackbar(_lang_header_vertical_layout.requests["701"], {variant: 'error'});
                break;
              }
              DialogBase.Update({ status: true, dialog_id: 0, maxWidth: "md" });
              break;
            case "/activekey":
              DialogBase.Update({ status: true, dialog_id: 1, maxWidth: "xs" });
              break;
            case "/requestmod":
              DialogBase.Update({ status: true, dialog_id: 4, maxWidth: "xs" });
              break;
            case "/download":
              window.open((SocialLinks.link_download_loader !== undefined) ? SocialLinks.link_download_loader : "/",'_blank')
              break;
            default:
              history(item.page_link);
          }
        }}
      >
        {(menu_alt || menu_alt_mob) ? 
        <div className="menu-item-link">
          {item.menu_svg_icon} {item.page_name}
        </div> 
        :
        <Tooltip title={item.page_name} placement="right-start" arrow>
          <div className="menu-item-link text-tooltip-tfr">
            {item.menu_svg_icon}
          </div>
        </Tooltip>
      }
      </li>
    )
  )

  const component_menu_img      = ((menu_alt) ? 
    <div className="user-short-description">
      <div className="user-short-description-avatar user-avatar medium">
        <div className="user-avatar-border">
          <div className="hexagon-120-132" style={{width: "120px", height: "132px", position: "relative"}}>
            <Hexagon  width="120" height="132" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_backgoround}/>
          </div>
        </div>
        <div className="user-avatar-content">
          <div className="hexagon-image-82-90" style={{width: "82px", height: "90px", position: "relative"}}>
            <Hexagon  width="82" height="90" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_backgoround}/>
          </div>
        </div>
        <div className="user-avatar-progress">
          <div className="hexagon-progress-100-110" style={{width: "100px", height: "132px", position: "relative"}}>
            <Hexagon  width="100" height="110" style={{ position: 'absolute', top: '0px', left: '0px'}} backgroundImage={user_infos.user_avatar}/>
          </div>
        </div>
        <div className="user-avatar-progress-border">
          <div className="hexagon-border-100-110" style={{width: "100px", height: "110px", position: "relative"}}>
            <Hexagon  width="100" height="110" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_backgoround}/>
          </div>
        </div>
        <div className="user-avatar-badge">
          <div className="user-avatar-badge-border">
            <div className="hexagon-32-36" style={{width: "32px", height: "36px", position: "relative"}}>
              <Hexagon  width="32" height="36" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_icon}/>
            </div>
          </div>
          <div className="user-avatar-badge-content">
            <div className="hexagon-dark-26-28" style={{width: "26px", height: "28px", position: "relative"}}>
              <Hexagon  width="26" height="28" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} />
            </div>
          </div>
          <p className="user-avatar-badge-text">{user_infos.user_level}</p>
        </div>
      </div>
      <p className="user-short-description-title">{user_infos.user_login}</p>
      <p className="user-short-description-text">{user_infos.user_phrase}</p>
    </div>
    :
    <div className="user-avatar small no-outline">
      <div className="user-avatar-content">
        <div className="hexagon-image-30-32" style={{width: "30px", height: "32px", position: "relative"}}>
          <Hexagon  width="30" height="32" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_backgoround} />
        </div>
      </div>
      <div className="user-avatar-progress">
        <div className="hexagon-progress-40-44" style={{width: "40px", height: "44px", position: "relative"}}>
          <Hexagon  width="40" height="44" style={{ position: 'absolute', top: '0px', left: '0px' }} backgroundImage={user_infos.user_avatar} />
        </div>
      </div>
      <div className="user-avatar-progress-border">
        <div className="hexagon-border-40-44" style={{width: "40px", height: "44px", position: "relative"}}>
          <Hexagon  width="40" height="44" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_backgoround} />
        </div>
      </div>
      <div className="user-avatar-badge">
        <div className="user-avatar-badge-border">
          <div className="hexagon-22-24" style={{width: "22px", height: "24px", position: "relative"}}>
            <Hexagon  width="22" height="24" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} backgroundImage={base_icon} />
          </div>
        </div>
        <div className="user-avatar-badge-content">
          <div className="hexagon-dark-16-18" style={{width: "16px", height: "18px", position: "relative"}}>
            <Hexagon  width="16" height="18" style={{ position: 'absolute', top: '0px', left: '0px', stroke: 'none' }} />
          </div>
        </div>
        <p className="user-avatar-badge-text">{user_infos.user_level}</p>
      </div>
    </div>
  )

  return(
    <>
      <div className="profile">
        <div id="mobile-profile-icon" className="mobile-nav-toggler" bis_skin_checked="1" onClick={colapse_menu_mobile}>
          <CreateSvg svgname="svg_grid" svgclassname="menu-item-link-icon icons_menu" />
        </div>

        <nav id="navigation-widget-small" className={`navigation-widget navigation-widget-v3 navigation-widget-desktop closed sidebar left ${(menu_colapse) ? 'hidden' : 'delayed'}`}>      
          {(!menu_alt) && component_menu_img}
          <ul className="menu small menu-v2">
            {component_menu_li}
            <li className="menu-item" onClick={colapse_menu}>
              <Tooltip title="Full" placement="right-start" arrow>
                <div className="menu-item-link text-tooltip-tfr">
                <CreateSvg svgname="svg_grid" svgclassname="menu-item-link-icon icons_menu" />
                </div>
              </Tooltip>
            </li>
          </ul>
        </nav>

        <nav id="navigation-widget" className={`navigation-widget navigation-widget-v2 navigation-widget-desktop sidebar left ${(!menu_colapse) ? 'hidden' : 'delayed'}`}>
          <figure className="navigation-widget-cover navigation-widget-cover-v2 liquid" style={{ background: `url(${user_infos.user_background}) center center / cover no-repeat` }}>
            <div className="navigation-widget-close-button navigation-widget-close-button-v2" onClick={colapse_menu}>
              <CreateSvg svgname="svg_back_arrow" svgclassname="navigation-widget-close-button-icon icon-back-arrow" />
            </div>
          </figure>
          {(menu_alt) && component_menu_img}
          <div className="badge-list small">
            {
              user_infos.user_badges.map((item, index) => 
                <div key={index} className="badge-item">
                  <img src={`/assets/img/badge/icons/${item}.png`} alt={_lang_base.web_site_name} />
                </div>
              )
            }
          </div>
          <div className="user-stats">
            <div className="user-stat">
              <p className="user-stat-title">{user_infos.user_posts}</p>
              <p className="user-stat-text">{_lang_header_vertical_layout.user_posts}</p>
            </div>
            <div className="user-stat">
              <p className="user-stat-title">{user_infos.user_folowers}</p>
              <p className="user-stat-text">{_lang_header_vertical_layout.user_likes}</p>
            </div>
            <div className="user-stat">
              <p className="user-stat-title">{user_infos.user_visits}</p>
              <p className="user-stat-text">{_lang_header_vertical_layout.user_visits}</p>
            </div>
          </div>
          <ul className="menu">{component_menu_li}</ul>
        </nav>

        <nav id="navigation-widget-mobile" className={`navigation-widget navigation-widget-mobile sidebar left ${(menu_mobile) ? 'delayed' : 'hidden'}`}>
          <div className="navigation-widget-close-button" onClick={colapse_menu_mobile}>
            <svg className="navigation-widget-close-button-icon icon-back-arrow" id="svg-back-arrow" viewBox="0 0 14 10" preserveAspectRatio="xMinYMin meet">
              <path d="M14,6H3.364l2.644,2.75L4.806,10L1.202,6.25l0,0L0,5L4.806,0l1.201,1.25L3.364,4H14V6z"/>
            </svg>
          </div>
          <div className="navigation-widget-info-wrap">
            <div className="navigation-widget-info">
              {(menu_alt_mob) && component_menu_img}
              <p className="navigation-widget-info-title">{user_infos.user_login}</p>
              <p className="navigation-widget-info-text">{user_infos.user_phrase}</p>
            </div>
            <p onClick={EXIT_ACCOUNT} className="navigation-widget-info-button button small secondary">{_lang_header_vertical_layout.user_logout}</p>
          </div>
          <ul className="menu">{component_menu_li}</ul>
        </nav>
      </div>
    </>
  )
}

export default HeaderVerticalLayout;
