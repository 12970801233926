import { useState, useContext, useRef, useEffect }    from "react";
import ReCAPTCHA                                      from "react-google-recaptcha";

import Context                                        from "../../Provider/Context";

import { useSnackbar }                                from 'notistack';
import Backdrop                                       from '@mui/material/Backdrop';
import CircularProgress                               from '@mui/material/CircularProgress';
import LinearProgress                                 from '@mui/material/LinearProgress';
import Typography                                     from '@mui/material/Typography';

import Stack                                          from "@mui/material/Stack";
import Stepper                                        from "@mui/material/Stepper";
import Step                                           from "@mui/material/Step";
import StepLabel                                      from "@mui/material/StepLabel";
import StepConnector, { stepConnectorClasses }        from "@mui/material/StepConnector";
import { styled }                                     from "@mui/material/styles";
import Box                                            from "@mui/material/Box";
import Button                                         from "@mui/material/Button";

import ThumbUpAltIcon                                 from "@mui/icons-material/ThumbUpAlt";
import YouTubeIcon                                    from "@mui/icons-material/YouTube";
import FacebookIcon                                   from "@mui/icons-material/Facebook";
import InstagramIcon                                  from "@mui/icons-material/Instagram";
import DiamondIcon                                    from "@mui/icons-material/Diamond";
import TimerIcon                                      from "@mui/icons-material/Timer";

import RECHARGE_AUTH                                  from "../../Utils/AUTH/RECHARGE_AUTH";

/* IMPORT LANGUAGE */
import { _lang_recharge, _lang_base }                 from "../../Languages/languages";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

const ColorlibStepIcon = (props) => {
  const { active, completed, className } = props;

  const icons = {
    1: <ThumbUpAltIcon />,
    2: <YouTubeIcon />,
    3: <FacebookIcon />,
    4: <InstagramIcon />,
    5: <DiamondIcon />,
    6: <TimerIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const parametrosJanela = "toolbar=no,location=no,directories=no,status=no, menubar=no,scrollbars=yes,resizable=yes,width=1000, height=1000";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1, }}>
        <LinearProgress sx={{height: "15px", borderRadius: 5}} variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function Recharge(props) {
  const { UserInfoBase }                            = useContext(Context);
  const UserInfo                                    = UserInfoBase.Value;

  const { RechargeLinks }                           = useContext(Context);
  const [link_id, set_link_id]                      = useState(0);

  const recaptchaRef                                = useRef();
  const { enqueueSnackbar }                         = useSnackbar();
  const [backdrop_open, set_backdrop_open]          = useState(false);

  const [btn_like, set_btn_like]                    = useState(false);
  const [btn_follow, set_btn_follow]                = useState(true);

  const [btn_open_recharge, set_btn_open_recharge]  = useState(false);
  const [cont_btn_click, set_cont_btn_click]        = useState(0);
  const [block_btn_prox, set_block_btn_prox]        = useState(true);

  const [progress, set_progress]                    = useState(0);
  const [verify_text, set_verify_text]              = useState(_lang_recharge.text_verify_waiting);
  const [verify_window, set_verify_window]          = useState(1);
  const [verify_cont_click, set_verify_cont_click]  = useState(true);

  const openJanela = async (url) => {
    const janela = window.open(url, `${Math.random()}`, parametrosJanela);
    if(janela == null) return set_verify_window(3);
    const verificador_da_janela = setInterval(() => {
      set_verify_window(2);
      clearInterval(verificador_da_janela);
    }, 15000);
  }
  
  const verify_progress = () => {
    const timer = setInterval(() => {
      set_progress((prevProgress) => {
        if (prevProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 125);
  }

  useEffect(() => {
    if (verify_window === 2) {
      if(verify_cont_click) {
        set_btn_like(true);
        set_btn_follow(false);
      } else {
        set_btn_like(true);
        set_btn_follow(true);
        set_block_btn_prox(false);
      }
      set_progress(0);
      set_verify_window(1);
      enqueueSnackbar(_lang_recharge.msg_verify_success, {variant: 'success'});
    } else if(verify_window === 3) {
      enqueueSnackbar(_lang_recharge.msg_error_adblock, {variant: 'error'});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify_window]);

  if(!UserInfo || UserInfo.user === undefined) return props.set_dialog_menu_open({ status: false });
  const function_recharge_type = (type, item) => {
    set_cont_btn_click(cont_btn_click + 1);
    if(type === "follow") {
      if(openJanela(item.link_chanel)) verify_progress();
      set_verify_cont_click(false);
      set_verify_text(_lang_recharge.text_verify_follown);
    } else {
      if(openJanela(item.link_video)) verify_progress();
      set_verify_cont_click(true);
      set_verify_text(_lang_recharge.text_verify_enjoy);
    }
  }

  const function_next_page     = (index) => {
    set_link_id(index + 1);
    set_btn_like(false);
    set_btn_follow(true);
    set_cont_btn_click(0);
    set_block_btn_prox(true);
    set_progress(0);
  }

  const function_recharge      = async () => {
    set_btn_open_recharge(true);
    try {
      set_backdrop_open(true);
      const rpf                   = (process.env.REACT_APP_LOCAL === "producao") ? await recaptchaRef.current.executeAsync() : undefined;
      const RESULT_RECHARGE_AUTH  = await RECHARGE_AUTH({ ...UserInfo, rpf });
      recaptchaRef.current.reset();
      set_backdrop_open(false);
      props.set_dialog_menu_open({ status: false });

      if(RESULT_RECHARGE_AUTH.success) {
        enqueueSnackbar(RESULT_RECHARGE_AUTH.status_code_message, {variant: 'success'});

        delete RESULT_RECHARGE_AUTH.status_code_message;
        delete RESULT_RECHARGE_AUTH.success;
        delete RESULT_RECHARGE_AUTH.status_code;

        UserInfoBase.Update(RESULT_RECHARGE_AUTH);
        return;
      }
      enqueueSnackbar(RESULT_RECHARGE_AUTH.status_code_message, {variant: 'error'});
    } catch (error) {
      set_backdrop_open(false);
      enqueueSnackbar(_lang_base.requests.error, {variant: 'error'});
      props.set_dialog_menu_open({ status: false });
    }
  }

  const component_recharge_type = RechargeLinks.map((item, index) => (index === link_id) &&
    <div key={index}>
      <section className="section recharge_type_container">
        <div className="grid grid-7-7-7 centered">
          <div className="user-preview user-preview-v2">
            <figure className="user-preview-cover user-preview-cover-v2 liquid" 
              style={{ 
                background: `url(https://projectdow.com/data${item.link_page_background_img}) center center / cover no-repeat`,
                opacity: "1"
              }} />
            <div className="user-preview-info">
              <div className="user-short-description">
                <div className="user-short-description-avatar user-avatar medium">
                  <img className="recharge_img" src={`https://projectdow.com/data${item.link_page_perfil_img}`} alt={_lang_base.web_site_name} />
                </div>
                <p className="user-short-description-title">{item.link_page_title}</p>
                <p className="user-short-description-text">{item.link_page_id}</p>
              </div>
              <div id="user-preview-stats-slides-01" className="user-preview-stats-slides">
                <div className="user-preview-stats-slide">
                  <p className="user-preview-text user-preview-text-v2">{item.link_description.pt_BR}</p>
                </div>
              </div>
              <div className="social-links small"></div>
              <div className="grid grid-7-7-7 centered">
                <div className="text-center"><h3>{verify_text}</h3></div>
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value={progress} />
                </Box>
              </div>
              <div className="user-preview-actions">
                <Button 
                  disabled={btn_like} 
                  size="large" 
                  variant="contained" 
                  color="secondary" 
                  sx={{ mr: 1, color: "white" }}
                  onClick={() => function_recharge_type("like", item)}
                >{_lang_recharge.like_video}
                </Button>
                <Button 
                  disabled={btn_follow} 
                  size="large" 
                  variant="contained" 
                  color="success" 
                  sx={{ color: "white" }} 
                  onClick={() => function_recharge_type("follow", item)}
                >{_lang_recharge.follow_channel}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button 
          onClick={() => props.set_dialog_menu_open({ status: false })} 
          size="large" 
          variant="contained" 
          color="error" 
          sx={{ mr: 1, color: "white" }}
        >{_lang_recharge.btn_cancel}
        </Button>
        <Button 
          disabled={block_btn_prox} 
          size="large" 
          variant="contained" 
          color="success" 
          sx={{ color: "white" }} 
          onClick={() => function_next_page(index)}
        >{_lang_recharge.btn_next}
        </Button>
      </Box>
    </div>
  )

  const component_recharge_button = 
    <section className="section recharge_type_container">
      <div className="grid grid-7-7-7 centered">
        <div className="user-preview user-preview-v2">
          <div className="user-preview-info">
            <div className="user-short-description">
              <div className="user-short-description-avatar user-avatar medium medium-v2">
                <img className="recharge_img" src="/assets/img/logo/icon_dark.png" alt={_lang_base.web_site_name} />
              </div>
            </div>
            <div id="user-preview-stats-slides-01" className="user-preview-stats-slides">
              <div className="user-preview-stats-slide">
                <p className="user-preview-text user-preview-text-v3">{_lang_recharge.sub_title}</p>
              </div>
            </div>
            <div className="social-links small"></div>
            <div className="user-preview-actions">
                <Button 
                  disabled={btn_open_recharge} 
                  size="large" 
                  variant="contained" 
                  color="success" 
                  sx={{ mr: 1, color: "white" }}
                  onClick={() => function_recharge()}
                >{_lang_recharge.btn_recharge}
                </Button>
            </div>
          </div>
        </div>
      </div>
    </section>

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY_SYSTEM}
      />
      <Backdrop
        sx={{ backgroundColor: "#000000bd", color: '#8841FB', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop_open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="container-recharge profile">
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Stepper alternativeLabel activeStep={link_id} connector={<ColorlibConnector />}>
            {Array(6).fill().map((_label, index) => (
              <Step key={index}><StepLabel StepIconComponent={ColorlibStepIcon} /></Step>
            ))}
          </Stepper>
        </Stack>
        { (RechargeLinks.length > link_id) && component_recharge_type }
        {(RechargeLinks.length <= link_id && RechargeLinks.length > 0) && component_recharge_button}
      </div>
    </>
  )
}

export default Recharge;
